import { getApi } from 'common/api-common'
import { blobbyStrings } from './strings'
import { Constants } from 'common/utils'
import standardiseTitle from 'project/standardiseTitle'

const pageTitle = 'Hoxton Mix'
const defaultStrings = {
  appOGLogo: 'https://www.hoxtonmix.com/images/hm-logo.jpg',
  appOGLogoAlt: `${Constants.companyInformation.name} logo`,
  appSiteName: Constants.companyInformation.name,
  breadcrumbHome: 'Homepage',
  indexDescription:
    'Virtual Offices, Private offices & Coworking in London. All the benefits of a prime London business address without the expensive overheads.',
  indexOGDescription: 'Mail Forwarding & Virtual Office Services in London',
  indexTitle: standardiseTitle(
    `Mail Forwarding & Virtual Office Services in London | ${pageTitle}`,
  ),
  organisationName: `Hoxton Mix`,
}
export const seoStrings = getApi().makeStrings({
  en: defaultStrings,
  es: blobbyStrings(defaultStrings),
})
